@charset "utf-8";

// Import a Google Font
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;500;600;700;800;900&display=swap");
@import "../node_modules/bulma/sass/utilities/initial-variables";
@import "../node_modules/bulma/sass/utilities/functions";
// Set your brand colors
$purple: #8a4d76;
$pink: #fa7c91;
$black: #3f3c37;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;
$white: rgb(253, 253, 253);
$orange: #ff914d; // rgb(255, 145, 77)

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$grey-dark: $black;
$grey-light: $beige-light;
$primary: $orange;
$link: $orange;
$widescreen-enabled: false;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $white;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;

@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/title.sass";
@import "../node_modules/bulma/sass/elements/table.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules//bulma/sass/components/dropdown.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/sass/components/card.sass";
@import "../node_modules/bulma/sass/helpers/flexbox.sass";
@import "../node_modules/bulma/sass/grid/columns.sass";
@import "../node_modules/bulma/sass/helpers/spacing.sass";

body {
  margin: 0;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 300;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3 {
  font-weight: 900;
  word-spacing: 0.06em;
}

h2 {
  font-size: 20px;
}

h1 {
  font-size: 24px;
}
