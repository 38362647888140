.sign-in {
  .title {
    margin: 10px 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  .forgotPassword {
    color: grey;
    text-decoration: underline;
  }
}

.icon-container {
  position: relative;
}

.icon {
  position: absolute;
  right: 0px;
  top: -20px;
}
