.custom-button {
  min-width: 165 px;
  width: auto;
  height: 48px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 16px 0 16px;
  // font-size: 16px;
  background-color: rgba(255, 153, 0, 0.952);
  color: white;
  text-transform: uppercase;
  font-weight: bolder;
  border: none;
  // border-radius: 5px;
  box-shadow: 0px 10px 35px rgba(242, 163, 45, 0.5);
  cursor: pointer;

  &:hover {
    background-color: rgb(240, 152, 21);
    color: white;
    border: none;
  }

  &.google-sign-in {
    background-color: #4285f4;
    box-shadow: 0px 10px 35px rgba(45, 75, 242, 0.5);
    color: white;

    &:hover {
      background-color: #357ae8;
      border: none;
    }
  }

  &.add-item {
    background-color: black;
    box-shadow: 0px 10px 35px rgb(61, 55, 46, 0.5);
    color: white;

    &:hover {
      background-color: rgb(250, 243, 235);
      color: rgb(22, 13, 1);
      border: 1px solid rgb(22, 13, 1);
      box-shadow: 0px 10px 35px rgba(238, 199, 152, 0.521);
    }
  }
}
