.item {
  flex: 25%;
  padding: 0 4px;
  max-width: 24rem;
  word-wrap: break-word;
  padding: 0.8em;
  background: white;
  border: 1.4px solid rgba(235, 196, 133);
  box-shadow: 0px 5px 15px rgba(235, 196, 133, 0.5);
  border-radius: 8px;
  margin: 8px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.icon-container {
  position: relative;

  .icon {
    position: absolute;
  }
}

.edit-icon {
  right: 20px;
  top: 0px;
}

.delete-icon {
  right: 0px;
  top: 0px;
}
