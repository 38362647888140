.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 80px;
}

.landing-image {
  height: 100%;
  width: 100%;
}