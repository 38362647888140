.header {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  .logo-container {
    padding: 25px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: space-between;
    color: rgb(240, 152, 21);
    font-size: larger;
    font-weight: 700;
  }

  .logo {
    width: 112px;
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .option {
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 400;
    color: #3f3c37;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: rgb(240, 152, 21);
      text-decoration: solid;
      //   border : 1px solid rgb(240, 152, 21);
      //   border-radius: 5px;
    }
  }
}
