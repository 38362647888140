.sign-up {
  .title {
    margin: 10px 0px;
  }
}

.icon-container {
  position: relative;
}

.icon {
  position: absolute;
  right: 0px;
  top: -20px;
}
