.items-collection {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

// @media (max-width: 800px) {
//   .items-collection {
//     flex-direction: column;
//   }
// }
